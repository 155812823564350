/* header */
.menu_site {
    display: flex;
    align-items: center;
    background-color: #fff;
    height: 100px;
    .container {
        display: flex;
        justify-content: space-between;
        @include mq_max (992px) {
            display: block;
            width: 100%;
        }
    }
    .logo {
        line-height: 75px;
        order: 1;
    }
    nav {
        display: flex;
        order: 2;
    }
    .logo, nav, .block_call {
        margin: 0 auto;
    }
    .block_call {
        order: 3;
        p {
            text-align: center;
            font-size: 18px;
        }
    }
    @include mq_min (1200px) {
        .navbar-collapse {
            line-height: 73px;
        }
    }
    @include mq_min_max (768px, 1200px) {
        .navbar-collapse {
            width: 100%;
            padding-top: 15px;
        }
    }
    @include mq_min_max (768px, 992px) {
        .logo {
            float: left;
        }
        .block_call {
            float: right;
        }
        nav {
            clear: both;
        }
    }
}
.menu {
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;  
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    li {
        padding: 0 12px;
        text-align: center;
    }
    a {
        color: black;
        font-size: 10px;
        font-weight: 400;
        letter-spacing: 0.1em;
        transition: all 0.25s;
        &:hover {
            background-color: #ffe561;
        }
    }
}
header {
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1;
    transition: height .4s;
    &.menu_site {
        @include mq_min_max (768px, 992px) {
            height: 140px;
        }
        @include mq_max (767px) {
            display: block;
            height: auto;
            min-height: 60px;
            .navbar {
                position: static;
            }
            .navbar-toggle{
                margin-right: 0px;
                position: absolute;
                top: 10px;
                right: 0px;
            }
            .navbar-collapse {
                padding-right: 0xp;
            }
            nav {
                float: right;
                margin-top: 10px;
                display: block;
            }
            .navbar-toggle .icon-bar {
                background-color: #000;
            }
            .menu {
                display: block;
                padding-bottom: 10px;
                li {
                    text-align: right;
                    padding: 0;
                    margin-bottom: 10px;
                    a {
                        font-size: 16px;
                    }
                }
            }
            .block_call {
                display: none;
            }
        }
    }
    &.scroll_menu {
        height: 90px;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        @include mq_min_max (767px, 992px) {
            height: 140px;
        }
        @include mq_max (767px) {
            height: auto;
            min-height: 60px;
        }
    }
}
/*end header*/