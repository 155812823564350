/*footer*/
footer {
    &.menu_site {
        height: 118px;  
        @include mq_max (992px) {
            .container {
                display: block;
            }
            .logo {
                float: left;
            }
            .block_call {
                float: right;
            }
            nav {
                display: none;
            }
        }
        @include mq_max (480px) {
            text-align: center;
            height: auto;
            padding-bottom: 20px;
            .logo,
            .block_call {
                float: none;
            }
        }
    }
}
/*end footer*/