/*main_block*/
.main_block {
    margin-top: 100px;
    padding-top: 100px;
    background: url(../img/bg_head.jpg) center no-repeat;
    background-size: cover;
    min-height: 600px;
    .wrap_text {
        padding-left: 59px;
        @include mq_max (1200px) {
            padding-left: 0px;
        }
        @include mq_max (767px) {
            text-align: center;
        }
    }
    .text_block {
        display: inline-block;
        margin-bottom: 24px;
        padding: 0 10px;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.1em;
        line-height: 30px;
        text-transform: uppercase;
        @include mq_max (992px) {
            font-size: 22px;
            line-height: 28px;
        }
        @include mq_max (767px) {
            font-size: 18px;
            line-height: 22px;
        }
    }
    .dark_block {
        background-color: #000;
        color: #ffd400;
    }
    .yellow_block {
        background-color: #ffd400;
        color: #000;
    }
    .wrap_form {
        .text_block {
            display: block;
            font-size: 18px;
            text-align: center;
        }
        @include mq_max (767px) {
            margin: 0 auto;
        }
    }
    @include mq_max (992px) {
        padding-top: 80xp;
    }
    @include mq_max (767px) {
        padding-top: 50px;
        margin-top: 60px;
    }
}
/*end main_block*/

/*about*/
.about {
    padding-bottom: 55px;
    background: url(../img/city.jpg) 50% -13px;
    .wrap_title_block {
        margin-bottom: 38px;
    }
    .wrap_text {
        padding-left: 155px;
        @include mq_max (1200px) {
            padding-left: 0px;
        }
        @include mq_max (767px) {
            margin-bottom: 20px;
            text-align: justify;
        }
    }
    .about_number {
        padding: 12px 18px; 
        width: 429px;
        border: 3px dashed #ffd400;
        color: black;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        span {
            padding: 0 5px;
            font-size: 24px;
            background-color: #ffd400;
        }
        @include mq_max (768px) {
            width: 100%;
        }
    }
    @include mq_max (767px) {
        background-position: center bottom;
        background-repeat: no-repeat;
    }
}
/*end about*/

/*the_best*/
.the_best {
    height: 668px;
    background: url(../img/bg_cran.jpg) center no-repeat;
    background-size: cover;
    .wrap_title_block {
        margin-bottom: 58px;
        background-color: #ffd400;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .subtitle_block {
        margin-bottom: 44px;
        @include mq_max (767px) {
            margin-bottom: 34px;
        }
    }
    .wrap_form {
        margin: 0 auto;
    }
    @include mq_max (767px) {
        height: auto;
    }
}
/*end the_best*/

/*services*/
.services {
    text-align: center;
    .title_block {
        margin-bottom: 55px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .services_subtitle {
        margin-bottom: 50px;
        @include mq_max (767px) {
            margin-bottom: 34px;
        }
    }
}
.list_services {
    display: flex;
    justify-content: space-between;
    .item_service {
        width: 170px;
        margin: 0 auto 25px;
        .wrap_thumb_service {
            width: 155px;
            height: 205px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 16px 18px 13px;
            background-color: #ffd400;
        }
        .name_service {
            color: black;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 0px;
            text-transform: uppercase
        }
        .short_desc_service {
            margin: 25px 0 45px;
            @include mq_max (767px) {
                margin: 20px 0;
            }
        }
    }
    @include mq_max (992px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
/*end services*/

/*benefits*/
.benefits {
    height: 669px;
    background: url(../img/bg_benefits.jpg) center no-repeat;
    background-size: cover;
    .title_block {
        margin-bottom: 66px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .list_benefits {
        width: 400px;
        margin: 0 auto;
        list-style-type: none;
        li {
            position: relative;
            padding: 10px 14px 10px 20px;
            background-color: #ffd400;
            &:before {
                content: '';
                position: absolute;
                left: -30px;
                width: 19px;
                height: 14px;
                background: url(../img/check.png);
            }
            @include mq_max (480px) {
                padding: 5px 14px 5px 20px;
            }
        }
        @include mq_max (480px) {
            width: 100%;
        }
    }
    @include mq_max (1200px) {
        height: 569px;
    }
    @include mq_max (767px) {
        height: auto;
    }
}
/*end benefits*/

/*sxema*/
.sxema {
    height: 588px;
    background: url(../img/bg_sxem.jpg) center bottom no-repeat;
    .title_block {
        margin-bottom: 50px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .subtitle_block {
        margin-bottom: 50px;
    }
    @include mq_max (992px) {
        height: auto;
    }
}
.block_sxema {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item_sxem {
        width: 130px;
        height: 130px;
        margin: 0 70px 70px 0;
        padding-top: 10px;
        position: relative;
        background-color: #ffd400;
        border-radius: 65px;
        text-align: center;
        &:before {
            content: '';
            position: absolute;
            left: 7px;
            top: -26px;
            width: 46px;
            height: 46px;
            background: url(../img/gear_1.png);
        }
        &:after {
            content: '';
            position: absolute;
            bottom: -25px;
            right: 18px;
            width: 57px;
            height: 57px;
            background: url(../img/gear_2.png);
        }
        &:nth-child(5){
            margin-right: 0px;
            .arrow_right {
                display: none;
            }
            @include mq_max (992px) {
                margin-right: 35px;
                .arrow_right {
                    display: block;
                }
            }
            @include mq_max (767px) {
                margin-right: 10px;
            }
        }
        &:nth-child(3),
            &:nth-child(6) {
            @include mq_max (992px) {
                .arrow_right {
                    display: none;
                }
            }
        }
        &:nth-child(9){
            .arrow_right {
                display: none;
            }
        }
        .arrow_right {
            position: absolute;
            top: 52px;
            right: -44px;
            width: 17px;
            height: 26px;
            background: url(../img/arrow_right.png);
            @include mq_max (767px) {
                display: none;
            }
        }
        @include mq_max (992px) {
            margin: 0 35px 70px 35px;
        }
        @include mq_max (767px) {
            margin: 0 10px 70px;
        }
    }
    .number_sxem {
        width: 35px;
        height: 35px;
        margin: 0 auto 5px;
        background: url(../img/wrap_number.png);
        line-height: 35px;
        color: black;
        font-size: 14px;
        font-weight: 400;
    }
}
/*end sxema*/

/*action*/
.action {
    height: 413px;
    background: url(../img/bg_action.jpg) center bottom no-repeat;
    .title_block {
        margin-bottom: 65px;
        @include mq_max (767px) {
            margin-bottom: 48px;
        }
    }
    .subtitle_block {
        margin-bottom: 45px;
        text-align: left;
    }
    .btn_send {
        width: 304px;
        @include mq_max (360px) {
            width: 100%;
        }
    }
}
/*end action*/

/*our_project*/
.our_project {
    .title_block {
        margin-bottom: 60px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .short_desc_project {
        margin: 40px 0;
        max-height: 90px;
        overflow: hidden;
        @include mq_max (767px) {
            margin: 20px 0;
        }
    }
    .item_project {
        @include mq_max (767px) {
            text-align: center;
            img {
                display: inline-block;
            }
        }
    }
    .prev_slide {
        @include mq_max (992px) {
            left: 0px;
        }
    }
    .next_slide {
        @include mq_max (992px) {
            right: 0px;
        }
    }
}
.wrap_slider {
    padding: 0 185px;
    @include mq_max (1200px) {
        padding: 0 80px;
    }
    @include mq_max (992px) {
        padding: 0 20px;
    }
}
.btn_slide {
    width: 17px;
    height: 26px;
    display: inline-block;
    position: absolute;
    top: 50%;
    margin-top: -13px;
    cursor: pointer;
}
.prev_slide {
    left: 95px;
    background: url(../img/arrow_left.png);
    @include mq_max (1200px) {
        left: 37px;
    }
    @include mq_max (992px) {
        left: 5px;
    }
}
.next_slide {
    right: 95px;
    background: url(../img/arrow_right.png);
    @include mq_max (1200px) {
        right: 37px;
    }
    @include mq_max (992px) {
        right: 5px;
    }
}
/*end our_project*/

/*client_about_us*/
.client_about_us {
    .title_block {
        margin-bottom: 83px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .item_testimonial {
        padding: 0 30px;
    }
    .wrap_photo {
        float: left;
        margin-right: 115px;
        @include mq_max (992px) {
            margin-right: 25px;
        }
        @include mq_max (767px) {
            float: none;
            text-align: center;
            margin-right: 0px;
            margin-bottom: 25px;
        }
    }
    .name_client {
        color: black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 40px;
        @include mq_max (767px) {
            margin-bottom: 10px;
        }
    }
}
/*end client_about_us*/

/*products*/
.products {
    .title_block {
        margin-bottom: 30px;
    }
    .subtitle_block {
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }
    .wrap_slider {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .item_product{
        width: 231px;
        margin-bottom: 20px;
        text-align: center;
        @include mq_max (767px) {
            width: 100%;
        }
    }
    .short_desc_product {
        margin-bottom: 30px;
        max-height: 90px;
        overflow: hidden;
    }
}
/*end products*/

/*contact*/
.contact {
    height: 400px;
    overflow: hidden;
    background-color: #ffd400;
    .title_block {
        margin-top: 55px;
        margin-bottom: 45px;
        text-align: left;
        padding: 0px;
        @include mq_max (767px) {
            margin-bottom: 38px;
        }
    }
    .address {
        font-size: 14px;
        line-height: 30px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        @include mq_max (767px) {
            margin-bottom: 20px;
        }
    }
    #map {
        position: absolute;
        height: 400px;
        width: 150%;
        @include mq_max (767px) {
            left: -15px;
        }
    }
    @include mq_max (767px) {
        height: 665px;
    }
}
/*end contact*/

/*question*/
.question {
    height: 700px;
    padding-top: 95px;
    background: url(../img/bg_question.jpg) center no-repeat;
    .title_block {
        margin-bottom: 10px;
    }
    .subtitle_block {
        font-size: 24px;
        margin-bottom: 60px;
        @include mq_max (767px) {
            font-size: 22px;
            margin-bottom: 38px;
        }
    }
    .wrap_form {
        margin: 0 auto;
    }
    @include mq_max (767px) {
        height: auto;
        padding: 60px 0 20px;
    }
}
/*end question*/

/**/
.fade {
    background-color: transparent;
    .modal-dialog {
        opacity: 0;
        display: flex;
        width: 100%;
        margin: 0;
    }
    .success_modal {
        margin: auto;
        font-size: 20px;
        font-weight: 700;
    }
}
.fade.in {
    background-color: #d5d5d5;
    display: flex !important;
    -webkit-transition: all .25s linear;
    -o-transition: all .25s linear;
    transition: all .25s linear;
    .modal-dialog {
        opacity: 1;
    }
    .close {
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 36px;
        opacity: .4;
    }
    .flex_centered {
        margin: auto;
    }
}
.fade.modal_white {
    background-color: #fff;
}
.fade {
    .name_project{
        color: black;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-transform: uppercase;
        margin-bottom: 70px;
        text-align: center;
        letter-spacing: 0.18em;
        @include mq_max (767px) {
            margin-bottom: 48px;
        }
    }
    .wrap_form {
        padding: 0 15px;
    }
    .wrap_img {
        float: left;
        margin-right: 70px;
        @include mq_max (767px) {
            float: none;
            display: block;
            text-align: center;
            margin-bottom: 20px;
            margin-right: 0px;
            img {
                margin: 0 auto;
            }
        }
    }
    .short_desc_product {
        @include mq_max (767px) {
            text-align: center;
        }
    }
}
.modal_order_product {
    .short_desc_product {
        overflow: hidden;
    }
    .btn_order {
        width: 264px;
        margin-top: 50px;
        @include mq_max (767px) {
            margin-bottom: 28px;
        }
    }
}
/*end */