* {
    font-family: 'PT Sans', sans-serif;
}

body {
    overflow-x: hidden;
    background-color: #fff;
}
@media (min-width: 1200px){
    .container {
        width: 1200px;
    }
}
.block {
    margin-top: 120px;
    @include mq_max (1200px) {
        margin-top: 100px;
    }
    @include mq_max (992px) {
        margin-top: 80px;
    }
    @include mq_max (767px) {
        margin-top: 60px;
    }
}
a {
    &:focus {
        outline: none;
    }  
    &:hover {
        text-decoration: none
    }
}
button {
    &:focus {
        outline: none !important;
    }
}
.form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #ffd400;
}

/* form */
.wrap_form {
    width: 304px;
    @include mq_max (360px) {
        width: 100%;
    }
}
.form-control {
    height: 64px;
    padding-left: 45px;
    background-color: white;
    border: 2px solid #000002;
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    &:focus {
        @include placeholder {
            color: transparent;
        }
    }
    @include mq_max (767px) {
        height: 54px;
        padding-left: 25px;
    }
}
.btn {
    background-color: #ffd400;
    border-radius: 0px;
    border: 2px solid #000002;
    color: black;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}
.btn_send {
    width: 100%;
    height: 64px;
    transition: all 0.4s;
    &:hover {
        background-color: #000;
        color: #ffd400;
    }
    @include mq_max (767px) {
        height: 54px;
    }
}
.btn_order {
    width: 100%;
    height: 54px;
    transition: all 0.4s;
    &:hover {
        background-color: #000;
        color: #ffd400;
    }
}
.btn_order_call {
    width: 160px;
    height: 40px;
    background-color: black;
    color: #ffd400;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    transition: all 0.4s;
    &:hover {
        background-color: #ffd400;
        color: #000;
    }
    &:focus {
        background-color: #ffd400;
        color: #000;        
    }
}

/* title */
.wrap_title_block {
    text-align: center;
}
.title_block {
    display: inline-block;
    margin-bottom: 0px;
    background-color: #ffd400;
    padding: 0 15px;
    color: black;
    font-size: 30px;
    font-weight: 700;
    line-height: 46px;
    text-transform: uppercase;
    letter-spacing: 0.18em;
    @include mq_max (767px) {
        font-size: 26px;
        line-height: 34px;
    }
}
.subtitle_block {
    color: black;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    text-align: center;
    line-height: 22px;
}

/* text */
.small_text {
    color: black;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2em;
    line-height: 18px;
}