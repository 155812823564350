@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}
@mixin mq_min_max($res-min, $res-max) {
    @media screen and (min-width: #{$res-min}+px) and (max-width: #{$res-max}+px)
    {
        @content;
    }
}

@mixin mq_min($res-min) {
    @media screen and (min-width: #{$res-min}+px) 
    {
        @content;
    }
}

@mixin mq_max($res-max) {
    @media screen and (max-width: #{$res-max}+px) 
    {
        @content;
    }
}

